import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from 'src/app/core/service/auth.service';
import { StorageService } from '../service/storage.service';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private storage: StorageService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const isAuthenticated = this.authService.isAuthenticated(next.queryParams.token);

    // Em caso de haver token e já estar autenticado
    if (isAuthenticated) {
      const user = this.storage.getUser();
      return this.canProceedToNextRoute(next, user);
    }

    // Em caso de não haver token
    if (!next.queryParams.token) {
      this.router.navigateByUrl('/access-forbidden');
      return false;
    }

    // Em caso de haver token, validar
    return this.authService.validateToken(next.queryParams.token, 'concentrated-attention-test')
      .pipe(
        catchError(err => {
          this.router.navigateByUrl('/access-forbidden');
          return throwError(err);
        }),
        map((user) => {
          return this.canProceedToNextRoute(next, user);
        })
      );
  }

  canProceedToNextRoute(next: ActivatedRouteSnapshot, user): boolean {
    // tslint:disable-next-line: max-line-length
    if ((next.routeConfig.path === 'concentracao' || next.routeConfig.path === 'concentracao/page') && user.concentratedAttentionTest.results) {
      const diff = diffMonths(new Date(user.concentratedAttentionTest.finishDate))
      if (diff > 6) {
        return true;
      }

      this.router.navigateByUrl(`/concentracao/resultado?token=${next.queryParams.token}`);
      return false;
    }

    if (next.routeConfig.path === 'concentracao/resultado' && !user.concentratedAttentionTest.results) {
      this.router.navigateByUrl(`/concentracao?token=${next.queryParams.token}`);
      return false;
    }

    return true;
  }

}

function diffMonths(dt1, dt2 = new Date()) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= (60 * 60 * 24 * 7 * 4);
  return Math.abs(Math.round(diff));
}
