import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { StorageService } from './storage.service';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable()

export class AuthService {

  constructor(
    private http: HttpClient,
    private storage: StorageService,
  ) { }

  url: string = environment.url;

  isAuthenticated(usedToken: string): boolean {
    const token = this.storage.getUserToken();
    const user = this.storage.getUser();

    return Boolean(token && token === usedToken && user);
  }

  validateToken(token, testType): Observable<User> {
    return this.http.get<User>(`${this.url}/public/${testType}/${token}`)
      .pipe(
        tap((user: User) => {
          this.storage.saveUserToken(token);
          this.storage.saveUser(user);
        })
      );
  }
  validateTokenWithCandidate(token: string, candidate: string, testType: string): Observable<User> {
    return this.http.get<User>(`${this.url}/public/${testType}/${candidate}/${token}`)
      .pipe(
        tap((user: User) => {
          this.storage.saveUserToken(token);
          this.storage.saveUser(user);
        })
      );
  }

}
