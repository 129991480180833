import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
// import { InstructionsComponent } from './components/instructions/instructions.component';
import { DisablePasteCopyDirective } from './directives/disable-paste-copy.directive';
import { SuccessComponent } from './components/success/success.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    // InstructionsComponent,
    DisablePasteCopyDirective,
    SuccessComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    DisablePasteCopyDirective
  ]
})

export class CoreModule {}
