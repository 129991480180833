import { IconModel } from '../models/concentration-test';

// symbol1 ==> setinha preta
// symbol2 ==> setinha branca com bolinha
// symbol3 ==> setinha branca

export const SCHOOLING_LEVELS = [{
  value: 'ens-fund-inc',
  name: 'Fundamental incompleto'
}, {
  value: 'ens-fund-curs',
  name: 'Fundamental cursando'
}, {
  value: 'ens-fund-comp',
  name: 'Fundamental completo'
}, {
  value: 'ens-med-inc',
  name: 'Médio incompleto'
}, {
  value: 'ens-med-curs',
  name: 'Médio cursando'
}, {
  value: 'ens-med-comp',
  name: 'Médio completo'
}, {
  value: 'tec-med-inc',
  name: 'Técnico incompleto'
}, {
  value: 'tec-med-curs',
  name: 'Técnico cursando'
}, {
  value: 'tec-med-comp',
  name: 'Técnico completo'
}, {
  value: 'ens-sup-inc',
  name: 'Superior incompleto'
}, {
  value: 'ens-sup-curs',
  name: 'Superior cursando'
}, {
  value: 'ens-sup-comp',
  name: 'Superior completo'
}];

export const testPreviewArray: Array<IconModel> =
  [
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-left', correct: false },
  ];

export const testArray: Array<IconModel>[] =
  [[
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-right', correct: false },
  ],
  [
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-left', correct: true }
  ],
  [
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-right', correct: false },
  ],
  [
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-top', correct: false },
  ],
  [
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol1-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
  ],
  [
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-left', correct: false },
  ],
  [
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-top', correct: false },
  ],
  [
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
  ],
  [
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-left', correct: false },
  ],
  [
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
  ],
  [
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-top', correct: false },
  ],
  [
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
  ],
  [
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-top', correct: false },
  ],
  [
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
  ],
  [
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
  ],
  [
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
  ],
  [
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-top', correct: false },
  ], [
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-top', correct: false },
  ],
  [
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
  ],
  [
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol1-left', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
  ],
  [
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-left', correct: true },
    { symbol: 'symbol3-right', correct: false },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol3-top', correct: false },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
    { symbol: 'symbol1-right', correct: true },
    { symbol: 'symbol1-bottom', correct: false },
    { symbol: 'symbol2-left', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-top', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol1-top', correct: false },
    { symbol: 'symbol2-right', correct: false },
    { symbol: 'symbol3-bottom', correct: false },
    { symbol: 'symbol2-bottom', correct: true },
  ]
  ];
