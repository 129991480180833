import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';
import { ConcentratedAttetionTestResult } from '../models/user';

@Injectable()

export class ConcentratedAttentionTestService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  url: string = environment.url;

  saveProfileInfo(params = {}): Observable<User> {
    const token = this.storageService.getUserToken();
    return this.http.post<User>(`${this.url}/public/concentrated-attention-test/${token}/edit-profile-info`, params)
      .pipe(tap((data: User) => this.storageService.saveUser(data)));
  }

  setStartTime(): Observable<User> {
    const token = this.storageService.getUserToken();
    return this.http.post<User>(`${this.url}/public/concentrated-attention-test/${token}/start`, {})
      .pipe(tap((data: User) => this.storageService.saveUser(data)));
  }

  finishTest(params = {}): Observable<ConcentratedAttetionTestResult> {
    const token = this.storageService.getUserToken();
    return this.http.post<ConcentratedAttetionTestResult>(`${this.url}/public/concentrated-attention-test/${token}/complete`, params)
      .pipe(tap((data: ConcentratedAttetionTestResult) => {
        const user = this.storageService.getUser();
        user.concentratedAttentionTest.results = data;
        this.storageService.saveUser(user);
      }));
  }
}
