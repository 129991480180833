import { Injectable } from '@angular/core';

import { User, VacancyCandidate } from '../models/user';

@Injectable()
export class StorageService {

  user: User | VacancyCandidate;
  token: string;

  constructor() {
    this.user = this.getUser();
    this.token = this.getUserToken();
  }

  saveUser(user: any) {
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser(): any {
    const user = this.user || JSON.parse(localStorage.getItem('user'));

    // As formas bonitas (.assign e spread) não funcionam e ninguém sabe por que
    // if (user) {
    //   return Object.assign({}, user); // Não funciona
    //   return { ...user };  // Não funciona
    //   return JSON.parse(JSON.stringify(user));  // Funciona
    // }

    return user ? JSON.parse(JSON.stringify(user)) : undefined;
  }

  saveUserToken(token: string) {
    this.token = token;
    localStorage.setItem('authToken', token);
  }

  getUserToken(): string {
    if (this.token) {
      return this.token;
    }

    this.token = localStorage.getItem('authToken');
    return this.token;
  }

  clearStorage(): void {
    this.user = this.token = undefined;
    localStorage.clear();
  }
}
