import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMinutes'
})

export class SecondsToMinutesPipe implements PipeTransform {

  SECONDS_PER_MINUTES = 60;

  transform(sec) {
    const minutes = sec / this.SECONDS_PER_MINUTES;
    const minutesInteger = Math.floor(minutes);
    const seconds = (minutes - minutesInteger) * this.SECONDS_PER_MINUTES;
    let secondsInteger: any = Math.round(seconds);

    if (secondsInteger < 10) {
      secondsInteger = '0' + secondsInteger;
    }

    return `${minutesInteger}:${secondsInteger}`;
  }

}
