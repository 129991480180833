import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { VacancyCandidate } from '../models/user';
import { StorageService } from './storage.service';
import { catchError, map, tap } from 'rxjs/operators';


@Injectable()

export class TypingTestService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router
  ) { }

  url: string = environment.url;

  startTest(params = {}): Observable<VacancyCandidate> {
    const token = this.storageService.getUserToken();
    const vacancyCandidateId = this.storageService.getUser()._id;
    return this.http.post<VacancyCandidate>(`${this.url}/public/typing-test/${vacancyCandidateId}/${token}/start`, params)
    .pipe(
      map((data: VacancyCandidate) => {
        this.storageService.saveUser(data);
        return data;
      }),
      catchError((error: any) => {
        this.router.navigateByUrl('/access-forbidden');
        return throwError(error);
       })
    )
  }

  finisheTest(params = {}): Observable<VacancyCandidate> {
    const token = this.storageService.getUserToken();
    const vacancyCandidateId = this.storageService.getUser()._id;
    return this.http.post<VacancyCandidate>(`${this.url}/public/typing-test/${vacancyCandidateId}/${token}/complete`, params)
    .pipe(tap((data: VacancyCandidate) => this.storageService.saveUser(data)))
  }



}
