import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from '../../../core/service/storage.service';
import { User, ConcentratedAttetionTestResult } from 'src/app/core/models/user';

import TEST_RESULTS_TEXTS from '../../../core/constants/test-results-texts';

@Component({
  selector: 'app-concentration-test-results',
  templateUrl: './results.component.html',
  styleUrls: ['../test-preview/test-preview.component.scss', '../concentration.component.scss']
})

export class ConcentrationTestResultsComponent implements OnInit {

  @ViewChildren('concentrationTest') concentrationTest: QueryList<any>;

  user: User;
  testResults: ConcentratedAttetionTestResult;
  resultsText: any;

  TEST_RESULTS_TEXTS = TEST_RESULTS_TEXTS;

  constructor(
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = this.storageService.getUser();

    if (!this.user.concentratedAttentionTest.beginDate && !this.user.concentratedAttentionTest.finishDate) {
      const token = this.storageService.getUserToken();
      this.router.navigateByUrl(`/concentracao?token=${token}`);
      return;
    }

    this.testResults = this.user.concentratedAttentionTest.results;
    this.resultsText = this.TEST_RESULTS_TEXTS.find(result => result.key === this.testResults.classification);
  }

}
