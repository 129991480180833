// tslint:disable: max-line-length

export default  [{
  key: 'inferior',
  title: 'Baixa',
  htmlDescription: '<p class="gray-text paragraph">Olá!</p> <p class="gray-text paragraph">Você tem <span class="font-weight-bold">foco e concentração um pouco abaixo da média da população</span>. Você pode melhorar praticando atividades como o jogos do sete erros, Sudoku, palavras cruzadas ou quebra-cabeças, pode aumentar seu nível de concentração com o tempo. Seu resultado foi encaminhado para a empresa. Aguarde novo contato!</p>'
}, {
  key: 'medio-inferior',
  title: 'Médio inferior',
  htmlDescription: '<p class="gray-text paragraph">Olá!</p> <p class="gray-text paragraph">Percebemos que você tem potencial para melhorar seu <span class="font-weight-bold">foco e concentração</span>. Quer uma dica? Se você praticar jogo do sete erros, Sudoku, palavras cruzadas ou quebra-cabeças, pode aumentar seu nível de concentração com o tempo. Seu resultado foi encaminhado para a empresa. Aguarde novo contato! </p>'
}, {
  key: 'medio',
  title: 'Médio',
  htmlDescription: '<p class="gray-text paragraph">Olá!</p> <p class="gray-text paragraph">Você tem <span class="font-weight-bold">foco e concentração na média da população</span>. Seu resultado foi encaminhado para a empresa. Aguarde novo contato! </p>'
}, {
  key: 'medio-superior',
  title: 'Médio alta',
  htmlDescription: '<p class="gray-text paragraph">Parabéns!</p> <p class="gray-text paragraph"> Você tem <span class="font-weight-bold">foco e concentração um pouco acima média da população</span>. Seu resultado foi encaminhado para a empresa. Aguarde novo contato! </p>'
}, {
  key: 'superior',
  title: 'Alta',
  htmlDescription: '<p class="gray-text paragraph">Parabéns!</p> <p class="gray-text paragraph"> Você tem <span class="font-weight-bold">alto foco e concentração</span>. Seu resultado foi encaminhado para a empresa. Aguarde novo contato! </p>'
}, {
  key: 'muito-superior',
  title: 'Muito alta',
  htmlDescription: '<p class="gray-text paragraph">Parabéns!</p> <p class="gray-text paragraph"> Você tem <span class="font-weight-bold">altíssimo foco e concentração</span>. Seu resultado foi encaminhado para a empresa. Aguarde novo contato! </p>'
}];
