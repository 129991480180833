import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[appDisablePasteCopy]",
})
export class DisablePasteCopyDirective {
  constructor() {

  }

  @HostListener("copy", ["$event"]) bockCopy(e: KeyboardEvent) {;
    e.preventDefault();
  }

  @HostListener("cut", ["$event"]) bockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener("paste", ["$event"]) bockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }
}
