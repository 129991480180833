import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../service/storage.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  constructor(private storageService: StorageService ) { }

  ngOnInit() {
    this.storageService.clearStorage();
  }

}
