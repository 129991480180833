import { Component, ViewChild, ElementRef, Input, Output, OnChanges, OnInit, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { testArray } from '../../../core/constants/constants';
import { ConcentratedAttentionTestService } from 'src/app/core/service/concentrated-attention-test.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-concentration-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss', '../concentration.component.scss']
})

export class ConcentrationTestComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    private concentratedAttentionTestService: ConcentratedAttentionTestService
  ) { }

  @Input() countDownHasEnded: boolean;
  @ViewChild('testBlocker') testBlocker: ElementRef;
  @Output() timeEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() finish: EventEmitter<any> = new EventEmitter<any>();

  testArray = testArray;
  userErrors = 0;
  userHits = 0;
  userOmissions = 0;
  rightIcons = ['symbol1-right', 'symbol2-bottom', 'symbol3-left'];
  lastIcomClicked: [number, number];

  SECONDS_PER_MINUTES = 60;
  TIME = 300;
  timer;
  maskedTimer;
  timeRunningOut = false;

  ngOnInit() {
    this.maskedTimer = this.maskTime(this.TIME);
  }

  ngOnChanges() {
    if (this.countDownHasEnded) {
      this.startTimer();
    }
  }

  ngOnDestroy() {
    this.calcOmissions();
    this.finish.emit({
      userHits: this.userHits,
      userErrors: this.userErrors,
      userOmissions: this.userOmissions,
      timeRemaining: this.TIME
    });

    clearInterval(this.timer);
  }

  startTimer() {
    this.maskedTimer = this.maskTime(this.TIME);

    this.timer = setInterval(() => {
      this.TIME--;
      this.maskedTimer = this.maskTime(this.TIME);
      if (!this.TIME) {
        clearInterval(this.timer);
        this.testBlocker.nativeElement.classList.toggle('d-none');
        this.calcOmissions();
        this.timeEnd.emit({
          userHits: this.userHits,
          userErrors: this.userErrors,
          userOmissions: this.userOmissions,
          timeRemaining: this.TIME
        });
      }
    }, 1000);

    this.concentratedAttentionTestService.setStartTime()
      .pipe(take(1))
      .subscribe(response => {});
  }

  checkIcon(row: number, column: number, currentLineIcon: HTMLImageElement, currentCircleIcon: HTMLImageElement) {
    const iconSelected = testArray[row][column];
    const variable = iconSelected.correct ? 'userHits' : 'userErrors';

    if (iconSelected.numberSelection === 1) {
      this.clickSecondTime(iconSelected, currentLineIcon, currentCircleIcon);
      this[variable]--;
    }

    if (!iconSelected.numberSelection) {
      this.clickFirstTime(iconSelected, currentLineIcon);
      this.setLastBiggestIcon(row, column);
      this[variable]++;
    }

  }

  clickFirstTime(icon, lineIcon) {
    icon.numberSelection = 1;
    lineIcon.classList.toggle('invisible');
  }

  clickSecondTime(icon, lineIcon, circleIcon) {
    icon.numberSelection = 2;
    circleIcon.classList.toggle('invisible');
    lineIcon.classList.toggle('invisible');
  }

  setLastBiggestIcon(row, column) {
    if (!this.lastIcomClicked || (row > this.lastIcomClicked[0]) ||
      (row === this.lastIcomClicked[0] && column > this.lastIcomClicked[1])) {
      this.lastIcomClicked = [row, column];
    }
  }

  calcOmissions() {
    if (this.lastIcomClicked) {
      const [row, column] = this.lastIcomClicked;

      for (let r = 0; r <= row; r++) {
        const columnLimit = r !== row ? 20 : column;
        for (let c = 0; c <= columnLimit; c++) {
          const item = this.testArray[r][c];

          if (item.correct && (!item.numberSelection || item.numberSelection !== 1)) {
            this.userOmissions++;
          }
        }
      }
    }
  }

  maskTime(sec) {
    const minutes = sec / this.SECONDS_PER_MINUTES;
    const minutesInteger = Math.floor(minutes);
    const seconds = (minutes - minutesInteger) * this.SECONDS_PER_MINUTES;
    let secondsInteger: any = Math.round(seconds);

    if (secondsInteger < 10) {
      secondsInteger = '0' + secondsInteger;
    }

    if (minutesInteger === 0 && secondsInteger <= 10) {
      this.timeRunningOut = true;
    }

    return `${minutesInteger}:${secondsInteger}`;
  }

}
