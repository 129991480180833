import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DeviceDetectionService } from 'src/app/core/service/device-detection.service';

@Injectable()
export class DeviceIsSupportedGuard implements CanActivate {
  constructor(
    private deviceDetection: DeviceDetectionService,
    private router: Router,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const device = this.deviceDetection.getDeviceType(navigator.userAgent);
    if (device === 'unable') {
      this.router.navigateByUrl('/platformnotsupported');
      return false;
    }

    return true;
  }

}
