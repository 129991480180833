import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {  CountdownModule } from 'ngx-countdown';

import { PipeModule } from './core/pipe/pipe.module';
import { CoreModule } from './core/core.module';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';


import { ConfirmDataUserComponent } from './tests/concentration/confirm-data-user/confirm-data-user.component';
import { ConcentrationTestPreviewComponent } from './tests/concentration/test-preview/test-preview.component';
import { ConcentrationTestComponent } from './tests/concentration/test/test.component';
import { ConcentrationTestResultsComponent } from './tests/concentration/results/results.component';

import { DeviceDetectionService } from './core/service/device-detection.service';
import { AuthService } from './core/service/auth.service';
import { ConcentratedAttentionTestService } from './core/service/concentrated-attention-test.service';
import { StorageService } from './core/service/storage.service';

import { PlatformNotSupportedComponent } from './core/components/platform-not-supported/platform-not-supported.component';
import { AccessForbiddenComponent } from './core/components/access-forbidden/access-forbidden.component';

import { IsAuthenticatedGuard } from './core/guards/is-authenticated.guard';
import { IsTypingTestAuthenticated } from './core/guards/is-typing-test-authenticated';
import { TypingTestService } from './core/service/typing-test.service';
import { DeviceIsSupportedGuard } from './core/guards/device-is-supported.guard';
import { TypingTestComponent } from './tests/typing/typing.component';



@NgModule({
  declarations: [
    AppComponent,
    ConfirmDataUserComponent,
    ConcentrationTestPreviewComponent,
    PlatformNotSupportedComponent,
    AccessForbiddenComponent,
    ConcentrationTestComponent,
    ConcentrationTestResultsComponent,
    TypingTestComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes),
    PipeModule,
    CoreModule,
    FontAwesomeModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CountdownModule
  ],
  providers: [
    DeviceDetectionService,
    AuthService,
    StorageService,
    ConcentratedAttentionTestService,
    DeviceIsSupportedGuard,
    IsAuthenticatedGuard,
    IsTypingTestAuthenticated,
    TypingTestService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
