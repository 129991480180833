import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { take } from 'rxjs/operators';

import TEST_RESULTS_TEXTS from '../../../core/constants/test-results-texts';
import { testPreviewArray } from '../../../core/constants/constants';

import { StorageService } from '../../../core/service/storage.service';
import { ConcentratedAttentionTestService } from '../../../core/service/concentrated-attention-test.service';
import { User, ConcentratedAttetionTestResult } from '../../../core/models/user';

@Component({
  selector: 'app-concentration-test-preview',
  templateUrl: './test-preview.component.html',
  styleUrls: [ './test-preview.component.scss', '../concentration.component.scss' ]
})

export class ConcentrationTestPreviewComponent implements OnInit {

  @ViewChildren('concentrationTest') concentrationTest: QueryList<any>;

  user: User;
  testResults: ConcentratedAttetionTestResult;
  resultsText: any;

  testPreviewArray = testPreviewArray;
  testePreviewCorrectIcons = [ 'symbol1-right', 'symbol2-bottom', 'symbol3-left' ];
  currentStep;
  stepsName = [ 'Introdução', 'Treino', 'Teste', 'Resultado' ];
  steps = [1, 1.1, 1.2, 2, 3, 4];
  stepIndex = 0;

  startTestCounter = 5; // 5 segundos
  timeHasEnded = null;
  confirmTestStart = false;

  TEST_RESULTS_TEXTS = TEST_RESULTS_TEXTS;

  constructor(
    private storageService: StorageService,
    private concentratedAttentionTestService: ConcentratedAttentionTestService
  ) {}

  ngOnInit() {
    this.user = this.storageService.getUser();
    this.currentStep = this.steps[this.stepIndex];
  }

  setClassStep(index) {
    if (this.currentStep >= index && this.currentStep < index + 1) {
      return 'purple';
    } else if (this.currentStep >= index + 1) {
      return 'green';
    }
    return '';
  }

  goToPreviousStep() {
    this.currentStep = this.steps[--this.stepIndex];
  }

  goToNextStep(event) {
    this.currentStep = this.steps[++this.stepIndex];

    if (this.currentStep === 3) {
      this.goToTestAnchor();
      this.confirmTestStart = false;
    }

    if (this.currentStep === 4) {
      this.timeHasEnded = false;
      scrollTo({ top: 0, left: 0, behavior: 'smooth' });

      // Corrigindo bug do firefox que o scrollTo com opções não é chamado no botão de Terminei o teste
      if (navigator.userAgent.match(/firefox/i) && event) {
        const buttonText = event.target.textContent;
        if (buttonText === 'Terminei o teste') {
          scrollTo(0, 0);
        }
      }

    }
  }

  goToTestAnchor() {
    this.concentrationTest.changes.subscribe(concentrationTest => {
      // o scroll só vai acontecer uma vez: quando muda para a tela de teste
      // se não tiver esse if, o scrollTop itrá rodar mais vezes
      if (this.startTestCounter) {
        const element = concentrationTest.first.nativeElement;
        const top = element.getBoundingClientRect().top;
        scrollTo(0, pageYOffset + top);
      }
    });
  }

  openConfirmTestStartModal() {
    this.confirmTestStart = true;
  }

  checkIcon(iconIndex: number, currentLineIcon: HTMLImageElement, currentCircleIcon: HTMLImageElement) {
    const iconSelected = testPreviewArray[iconIndex];
    const variable = iconSelected.correct ? 'userHits' : 'userErrors';

    if (iconSelected.numberSelection === 1) {
      this.clickSecondTime(iconSelected, currentLineIcon, currentCircleIcon);
      this[variable]--;
    }

    if (!iconSelected.numberSelection) {
      this.clickFirstTime(iconSelected, currentLineIcon);
      this[variable]++;
    }
  }

  clickFirstTime(icon, lineIcon) {
    icon.numberSelection = 1;
    lineIcon.classList.toggle('invisible');
  }

  clickSecondTime(icon, lineIcon, circleIcon) {
    icon.numberSelection = 2;
    circleIcon.classList.toggle('invisible');
    lineIcon.classList.toggle('invisible');
  }

  startTestCountdown() {
    const timer = setInterval(() => {
      if (!this.startTestCounter) {
        return clearInterval(timer);
      }
      this.startTestCounter--;
    }, 1000);
  }

  onTestTimeEnd(results) {
    this.timeHasEnded = true;
    this.calculateResult(results);
  }

  calculateResult(results) {
    const params = results;

    this.concentratedAttentionTestService.finishTest(params)
      .pipe(take(1))
      .subscribe(testResults => {
        this.testResults = testResults;
        this.resultsText = this.TEST_RESULTS_TEXTS.find(result => result.key === this.testResults.classification);
      });
  }
}
