import { Pipe, PipeTransform } from '@angular/core';
import { SCHOOLING_LEVELS } from '../constants/constants';

@Pipe({
  name: 'educationPipe'
})
export class EducationPipe implements PipeTransform {

  SCHOOLING_LEVELS_HELPER = {};

  constructor() {
    SCHOOLING_LEVELS.forEach(schoolingLevel => {
      this.SCHOOLING_LEVELS_HELPER[schoolingLevel.value] = schoolingLevel.name;
    });
  }

  transform(levelEducation) {
    return this.SCHOOLING_LEVELS_HELPER[levelEducation];
  }

}
