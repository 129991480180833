
import { Injectable } from '@angular/core';

@Injectable()

export class DeviceDetectionService {

  getDeviceType(userAgent: string) {
    if (this.isDesktop(userAgent) && !this.isMobile(userAgent)) {
      return 'able';
    }
    return 'unable';
  }

  getDeviceResolution() {
    return window.outerWidth;
  }

  isMobile(userAgent: string) {
    return !!userAgent.match(/Android|iPhone|Windows Phone/);
  }

  isDesktop(userAgent: string) {
    return !!userAgent.match(/Windows|Mac OS|Linux/);
  }

}
