import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { faAddressBook, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { SCHOOLING_LEVELS } from '../../../core/constants/constants';
import STATES from '../../../core/constants/states';

import { User } from 'src/app/core/models/user';
import { StorageService } from 'src/app/core/service/storage.service';
import { Subscription } from 'rxjs';
import { ConcentratedAttentionTestService } from 'src/app/core/service/concentrated-attention-test.service';
import { take } from 'rxjs/operators';
import { DeviceDetectionService } from '../../../core/service/device-detection.service';

@Component({
  selector: 'app-confirm-data-user',
  templateUrl: './confirm-data-user.component.html',
  styleUrls: ['./confirm-data-user.component.scss']
})

export class ConfirmDataUserComponent implements OnInit, OnDestroy {
  form: FormGroup;
  user: User;
  editing = false;

  showAddressWarningModal = false;

  SCHOOLING_LEVELS = SCHOOLING_LEVELS;
  STATES = STATES;
  cities: Array<string>;

  subscriptions: Array<Subscription> = [];

  faAddressBook = faAddressBook;
  faMapMarkerAlt = faMapMarkerAlt;

  showForm = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private concentratedAttentionTestService: ConcentratedAttentionTestService,
    private deviceDetection: DeviceDetectionService,
  ) { }

  ngOnInit() {
    const device = this.deviceDetection.getDeviceType(navigator.userAgent);
    if (device === 'unable') {
      // this.router.navigateByUrl('/platformnotsupported');
      this.showForm = false;
    } else {

      this.showForm = true;
    }

    this.user = this.storageService.getUser();

    this.initializeForm();
    this.fillForm();
    this.fillCitiesArray();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      basicInformation: this.formBuilder.group({
        levelEducation: ['', Validators.required],
      }),
      address: this.formBuilder.group({
        state: ['', Validators.required],
        city: ['', Validators.required],
      }),
    });
  }

  fillForm() {
    this.form.get('basicInformation').patchValue({
      levelEducation: this.user.basicInformation.levelEducation,
    });

    this.form.get('address').patchValue({
      state: this.user.address ? this.user.address.state : '',
      city: this.user.address ? this.user.address.city : '',
    });
  }

  fillCitiesArray() {
    const address = this.user.address;
    this.cities = address && address.city ? this.STATES.find(state => state.name === address.state).cities : null;

    const subscription = this.form.get('address.state').valueChanges.subscribe(value => {
      this.form.get('address.city').reset();
      if (value) {
        this.cities = this.STATES.find(state => state.name === value).cities;
      }
    });
    this.subscriptions.push(subscription);
  }

  showEditForm() {
    this.editing = true;
  }

  cancelEdition() {
    this.editing = false;
    this.fillForm();
  }

  saveEdition() {
    if (this.form.valid) {
      this.user.basicInformation.levelEducation = this.form.get('basicInformation.levelEducation').value;
      if (!this.user.address) {
        this.user.address = { state: '', city: '' };
      }

      this.user.address.state = this.form.get('address.state').value;
      this.user.address.city = this.form.get('address.city').value;
      this.editing = false;
    }
  }

  public saveProfileInfo() {
    const user = this.storageService.getUser();

    if (JSON.stringify(user.address) !== JSON.stringify(this.user.address)) {
      this.showAddressWarningModal = true;
    }

    const params = this.form.value;

    this.concentratedAttentionTestService.saveProfileInfo(params)
      .pipe(take(1))
      .subscribe(response => {
        if (!this.showAddressWarningModal) {
          this.goToTestPreview();
        }
      });
  }

  goToTestPreview() {
    this.router.navigate(['concentracao/page'], {
      queryParams: {
        token: this.storageService.getUserToken()
      }
    });
  }
}
