import { Routes } from '@angular/router';
import { PlatformNotSupportedComponent } from './core/components/platform-not-supported/platform-not-supported.component';
import { AccessForbiddenComponent } from './core/components/access-forbidden/access-forbidden.component';
import { DeviceIsSupportedGuard } from './core/guards/device-is-supported.guard';
import { IsAuthenticatedGuard } from './core/guards/is-authenticated.guard';
import { ConcentrationTestResultsComponent } from './tests/concentration/results/results.component';
import { ConcentrationTestPreviewComponent } from './tests/concentration/test-preview/test-preview.component';
import { ConfirmDataUserComponent } from './tests/concentration/confirm-data-user/confirm-data-user.component';
import { TypingTestComponent } from './tests/typing/typing.component';
import { IsTypingTestAuthenticated } from './core/guards/is-typing-test-authenticated';
import { SuccessComponent } from './core/components/success/success.component';

export const AppRoutes: Routes = [{
  path: '',
  redirectTo: '/concentracao',
  pathMatch: 'full'
}, {
  path: 'concentracao',
  component: ConfirmDataUserComponent,
  canActivate: [IsAuthenticatedGuard],
}, {
  path: 'concentracao/page',
  component: ConcentrationTestPreviewComponent,
  canActivate: [DeviceIsSupportedGuard, IsAuthenticatedGuard],
}, {
  path: 'concentracao/resultado',
  component: ConcentrationTestResultsComponent,
  canActivate: [DeviceIsSupportedGuard, IsAuthenticatedGuard]
}, {
  path: 'platformnotsupported',
  component: PlatformNotSupportedComponent
}, {
  path: 'digitacao',
  component: TypingTestComponent,
  canActivate: [DeviceIsSupportedGuard, IsTypingTestAuthenticated]
}, {
  path: 'sucesso',
  component: SuccessComponent
}, {
  path: 'access-forbidden',
  component: AccessForbiddenComponent
}];
