import { NgModule } from '@angular/core';
import { SecondsToMinutesPipe } from './seconds-to-minutes.pipe';
import { EducationPipe } from './education.pipe';

@NgModule({
  declarations: [
    SecondsToMinutesPipe,
    EducationPipe
  ],
  exports: [
    SecondsToMinutesPipe,
    EducationPipe
  ]
})

export class PipeModule {}
