import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from 'src/app/core/service/auth.service';

@Injectable()
export class IsTypingTestAuthenticated implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    // const isAuthenticated = this.authService.isAuthenticated(route.queryParams.token);

    if(!route.queryParams.token) {
      this.router.navigateByUrl('/access-forbidden');
      return false;
    }

    return this.authService.validateTokenWithCandidate(route.queryParams.token, route.queryParams.user, 'typing-test')
      .pipe(catchError(error => {
        this.router.navigateByUrl('/access-forbidden');
        return throwError(error);
      }),
      map((user) => {
        return true;
      })
    );

  }
}
