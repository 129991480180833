import { Component, Input } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: [ 'header.component.scss' ]
})
export class HeaderComponent {

  @Input() hasMenu: boolean;

  faBars = faBars;
  isMenuOpened = false;

  toggleMenu() {
    this.isMenuOpened = !this.isMenuOpened;
  }

}
