import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CountdownComponent } from 'ngx-countdown';

import { TypingTestService } from '../../core/service/typing-test.service';
import { StorageService } from '../../core/service/storage.service';
import normalizeString from '../../core/helpers/normalizeString';
import { VacancyCandidate } from 'src/app/core/models/user';

@Component({
  selector: 'app-typing',
  templateUrl: './typing.component.html',
  styleUrls: ['./typing.component.scss']
})
export class TypingTestComponent implements OnInit {
  @ViewChild(CountdownComponent) private countdown: CountdownComponent;
  instructions: string[] = [
    'Certifique-se de utilizar um computador e uma conexão estável com a internet.',
    // tslint:disable-next-line: max-line-length
    // 'Se você sair da página após iniciar o teste não poderá fazê-lo novamente',
    'Ao iniciar o teste você irá visualizar um texto e deverá digitá-lo no campo indicado, exatamente como mostrado. Só será possível finalizar o teste após o texto completo ser copiado.',
    // tslint:disable-next-line: max-line-length
    'Você terá apenas alguns minutos para esta tarefa, assim que digitar todo o conteúdo basta clicar no botão <b>"Finalizar"</b>. Caso o tempo acabe seu teste será finalizado automaticamente. Uma vez iniciado o teste não poderá ser pausado.',
  ];
  modalIsOpen = true;
  defaultText: string = '' as string;
  text = '';
  isFinished = false;
  isTimerOver = false;
  config = { leftTime: 0, template: `$!m!:$!s!`, demand: true };

  constructor(
    private typingTestService: TypingTestService,
    private storageService: StorageService,
    private router: Router,
  ) { }

  ngOnInit() {
    const vacancyCandidate: VacancyCandidate = this.storageService.getUser();
    this.defaultText = vacancyCandidate.typingTest[0].defaultText;
    this.config.leftTime = vacancyCandidate.typingTest[0].maxDuration;
  }

  startTestCountdown() {
    this.countdown.begin();
    this.typingTestService.startTest({
      beginDate: new Date().toISOString()
    })
      .pipe(take(1))
      .subscribe(response => {
        // tslint:disable-next-line: no-unused-expression
      }), (err) => this.router.navigateByUrl('/access-forbidden');
    this.modalIsOpen = false;
  }

  timeIsOver(e) {
    this.typingTestService.finisheTest({
      text: this.text,
      finishedDate: new Date().toISOString()
    }).pipe(take(1))
      .subscribe(response => {
        return this.router.navigateByUrl('/sucesso');
      }, (err) => this.router.navigateByUrl('/access-forbidden'));

    this.isTimerOver = true;
  }

  onFinished() {
    this.typingTestService.finisheTest({
      text: this.text,
      finishedDate: new Date().toISOString()
    }).pipe(take(1))
      .subscribe(response => {
        return this.router.navigateByUrl('/sucesso');
      }, (err) => this.router.navigateByUrl('/access-forbidden'));
    this.countdown.stop();
  }

  watchText(content): void {
    if (content === this.defaultText || normalizeString(content) === normalizeString(this.defaultText)) {
      this.isFinished = true;
    }
  }
}
